import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const FormWithCaptcha = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });

  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsCaptchaValid(!!value);  // CAPTCHA is valid if there is a value
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      setIsCaptchaValid(false);
      return;
    }

    setIsSubmitting(true);
    
    // Simulate form submission
    setTimeout(() => {
      alert('Form submitted successfully');
      setIsSubmitting(false);
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </div>

      {/* reCAPTCHA component */}
      <div>
        <ReCAPTCHA
          sitekey="YOUR_GOOGLE_RECAPTCHA_SITE_KEY"
          onChange={handleCaptchaChange}
        />
        {!isCaptchaValid && <p style={{ color: 'red' }}>Please complete the CAPTCHA</p>}
      </div>

      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </form>
  );
};

export default FormWithCaptcha;
