import React from 'react'
import { useNavigate } from 'react-router-dom'
import Blogs from '../Subjects/Blogs'
import Main_footer from '../Footer'
import FAQ from '../Subjects/FAQ'
import Layout8 from '../Subjects/Layout8'
import Navbar from '../Navbar'
import Backtotop from '../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Work_svg from '../How-it-works/works-svgs/Work_svg'
import Work_svg1 from '../How-it-works/works-svgs/Work_svg1'
import Work_svg2 from '../How-it-works/works-svgs/Work_svg2'
import Benefits_to_student from '../Benefits_to_student'
import What_will_you_be from '../What_will_you_be'
import Assessment from '../Assessment'
import { offline } from '../../Contents/Offline'
import { Helmet } from 'react-helmet'

const Offline = () => {
    const navigate=useNavigate()

  return (
   <div>
     <Helmet>
    <title>Expert Offline Tuition Classes | Merida Tuition Tutor</title>
      <meta name="description" content="Find the perfect offline tutor for all subjects & grades! Merida Tuition provides personalized & effective offline tuition classes at your convenience. Enroll Now!" />
      {/* <meta name="keywords" content="services, products, my website" /> */}
    </Helmet>
    <div className='animate__animated animate__fadeIn'>
      <div className="offline-div">
        <Navbar value={"offline"} color={'#7200D2'}/>
        <div className="row container m-0 mx-auto h-100 mt-4 mt-md-5">
            <div className="col-lg-6 d-flex flex-column justify-content-start pt-4">
            <div className="">
                <p className='offline-text text-white'>Experience Learning</p>
                <p className='offline-text text-white' style={{lineHeight:'60px'}}>Up Close with Offline Classes</p>
                <p className='text-white mt-5' style={{lineHeight:'30px'}}>Discover the joy of learning in person with our engaging offline classes. Get real-time feedback and enjoy a distraction-free environment for effective, interactive education.</p>

                <div className='mt-5'>
                    <button className='btn text-white fw-bold px-4 py-2 offline-btn' style={{backgroundColor:'#F74D4D',border:'1px solid #F74D4D'}} onClick={()=>{
                        navigate('/add_tutor')
                    }}>Explore more</button>
                </div>
            </div>

            </div>
        </div>
      </div>

{/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-4">
        <h1 className='fs-1 fw-bolder text-center mb-5'>Top Benefits of Offline Learning</h1>
        {/* <p className=' text-center mb-5 pb-4'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p> */}


        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Engaging One-on-One Sessions</p>
                <p className='mt-4 text-center'>Interact directly with expert tutors for clear, immediate answers and personalized support.</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg1/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Focused Study Environment</p>
                <p className='mt-4 text-center'>Enjoy learning without digital distractions, in a space designed for concentration and steady progress.</p>

            </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>Instant Support and Feedback</p>
                <p className='mt-4 text-center'>Get real-time help and quick feedback, ensuring you understand every topic thoroughly.</p>

            </div>
        </div>
        
      </div>
    
{/* Layout3 */}
<div className="board-subdiv2">
      <div className="row m-0 container mx-auto py-5">
        <h2 className='fs-1 fw-bold text-center'>Discover the Joy of Offline Education</h2>

        <div className='mt-4'>
          {offline.para && offline.para.map((x)=>{
            return(
              <>
              <p className='text-center mb-4 fw-bold fs-4'>{x.head}</p>
              <p className='text-center mb-4'>{x.para}</p>
              </>
            )
          })}
          </div>
      </div>
      </div>

      <div className="row m-0 container mx-auto py-5 my-4">
        <Benefits_to_student/>
      </div>

{/* Layout4 */}
<div className='py-5' style={{backgroundColor:'#f7f9ff'}}>
      <div className="row m-0 container mx-auto align-items-center">
<div className="col-md-7 text-center">
<img src={require('../../images/icon 2/img2.jpeg')} style={{width:'70%',borderRadius:'35px'}} alt="" />
</div>
<div className="col-lg-5 ms-auto">
            <h3 className='fs-3 fw-bolder m-0'>Best offline classes in Bangalore</h3>
            <p className='text-secondary mt-2' style={{fontSize:'14px'}}>Our offline classes make learning fun and engaging. Team up with caring tutors who offer personalized support to help you succeed.</p>

            <div className="row m-0">
                <div className="col-md-4 ps-md-0">
                    <div>
                        <p style={{color:'#8236C8'}} className='fw-bolder fs-3 m-0'>200K+</p>
                        <p style={{fontSize:'14px'}}>Token launches</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <p style={{color:'#8236C8'}} className='fw-bolder fs-3 m-0'>100%</p>
                        <p style={{fontSize:'14px'}}>Instant Buy-In</p>
                    </div>
                </div>
                <div className="col-md-4 pe-md-0">
                    <div>
                        <p style={{color:'#8236C8'}} className='fw-bolder fs-3 m-0'>24/7</p>
                        <p style={{fontSize:'14px'}}>Market Places</p>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <div className='d-flex align-items-start'>
                <p>  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg></p>

<div className='ms-3'>
    <p className='fw-bold mb-2 m-0'>Interactive Learning Experience:</p>
    <p className='text-secondary' style={{fontSize:'13px'}}>Participate in lively discussions and collaborative activities that make learning enjoyable and effective. With real-time interactions, you can ask questions and receive immediate feedback, ensuring a deeper understanding of the material.</p>
</div>
                </div>
                <div className='d-flex align-items-start'>
              <p>  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg></p>

<div className='ms-3'>
    <p className='fw-bold mb-2 m-0'>Dedicated Study Space:</p>
    <p className='text-secondary' style={{fontSize:'13px'}}>Learn in a distraction-free environment that helps you focus better. Our classrooms are set up to promote concentration, allowing you to dive deep into your studies without the interruptions of digital devices.</p>
</div>
                </div>
                <div className='d-flex align-items-start'>
                <p>  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
</svg></p>

<div className='ms-3'>
    <p className='fw-bold mb-2 m-0'>Immediate Support:</p>
    <p className='text-secondary' style={{fontSize:'13px'}}>Our tutors are readily available to assist you as you learn. Whether you need clarification on a topic or help with assignments, you’ll receive instant support that keeps you motivated and engaged.</p>
</div>
                </div>
                
            </div>
        </div>
      </div>
      </div>

{/* Layout5 */}
<div className="row m-0 container mx-auto py-5 align-items-center justify-content-center">
<p className='fs-1 fw-bold text-center' style={{lineHeight:'48px'}}>Why you should choose us?</p>
  <p className='text-secondary mb-5 text-center'>With lots of unique blocks, you can easily build a page without coding. Build your next landing page.</p>
<div className="col-md-6 pe-md-5">
  <img src={require('../../images/icon 2/WhatsApp Image 2024-09-19 at 10.18.31 AM (1).jpeg')} className='d-block mx-auto' style={{width:'80%',borderRadius:'25px'}} alt="" />
</div>

<div className="col-md-5">
  <div className='d-flex align-items-start'>
    <p className='rounded-circle fw-bold d-flex align-items-center justify-content-center' style={{color:'#473BF0',backgroundColor:'#ecebfd',height:'40px',width:'46px'}}>1</p>
    <div className='ms-4'>
      <p className='m-0 fw-bold fs-5'>Easy Booking</p>
      <p className='mt-3'>With lots of unique blocks, you can easily build a page without coding. </p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-4'>
  <p className='rounded-circle fw-bold d-flex align-items-center justify-content-center' style={{color:'#473BF0',backgroundColor:'#ecebfd',height:'40px',width:'46px'}}>2</p>
    
    <div className='ms-4'>
      <p className='m-0 fw-bold fs-5'>Free Expert Opinion</p>
      <p className='mt-3'>With lots of unique blocks, you can easily build a page without coding. </p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-4'>
  <p className='rounded-circle fw-bold d-flex align-items-center justify-content-center' style={{color:'#473BF0',backgroundColor:'#ecebfd',height:'40px',width:'46px'}}>3</p>

    <div className='ms-4'>
      <p className='m-0 fw-bold fs-5'>Get Your Results</p>
      <p className='mt-3'>With lots of unique blocks, you can easily build a page without coding. </p>
    </div>
  </div>
</div>
      </div>

<What_will_you_be/>
<Assessment/>
<Blogs/>
<Layout8/>
<FAQ/>
<Main_footer/>
<Backtotop/>
<Scrolltotop/>
    </div>
   </div>
  )
}

export default Offline
