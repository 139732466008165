import React from 'react'

const Assessment = () => {
  return (
    <div>
      <div className="row m-0 container mx-auto py-5 align-items-end justify-content-between">
    <h4 className='fw-bold fs-1 mb-5'>Assessments Made Simple</h4>
<div className="col-lg-2 mb-4 mb-md-0">

    <div className='rewards'>
        <img style={{position:'absolute',top:'-40px',width:'40%',left:'30%'}} src={require('../images/Get an Instant Offer.png')} alt="" />
        <p className='rewards-span mt-3'>Diagnostic Assessment</p>
        <p>Check prior knowledge</p>
    </div>
</div>
<div className="col-lg-2 mb-4 mb-md-0">
    <div className='rewards'>
    <img style={{position:'absolute',top:'-40px',width:'40%',left:'30%'}} src={require('../images/Get an Instant Offer (2).png')} alt="" />

    <p className='rewards-span mt-3'>Formative Assessment</p>

        <p>Quick Quizzes & Tests</p>
        <p>Practical Problem Solving</p>
    </div>
</div>
<div className="col-lg-2 mb-4 mb-md-0">

    <div className='rewards'>
    <img style={{position:'absolute',top:'-40px',width:'40%',left:'30%'}} src={require('../images/Group 1171276109.png')} alt="" />

    <p className='rewards-span mt-3'>Summative Assessment</p>
        <p>End-of-Course Exams</p>
        <p>Standardized Performance Tests</p>
        <p>Analytical Essays</p>
    </div>
</div>
<div className="col-lg-2 mb-4 mb-md-0">

    <div className='rewards'>
    <img style={{position:'absolute',top:'-40px',width:'40%',left:'30%'}} src={require('../images/Get an Instant Offer (3).png')} alt="" />

    <p className='rewards-span mt-3'>Capstone Projects</p>

        <p>Final Project Presentation</p>
        <p>Detailed Research Assignment</p>
        <p>Real-World problem solutions</p>
        <p>Industry-Relevant Solutions</p>
    </div>
</div>
<div className="col-lg-2">

    <div className='rewards'>
    <img style={{position:'absolute',top:'-40px',width:'40%',left:'30%'}} src={require('../images/Group 1171276107.png')} alt="" />
    <p className='rewards-span mt-3'>Progress Portfolios</p>
        <p>Collection of Student Work</p>
        <p>Detailed Feedback Reviews</p>
        <p>Academic Growth Analysis</p>
        <p>Achievement Metrics Overview</p>
        <p>Progress tracking</p>
    </div>
</div>
</div>
    </div>
  )
}

export default Assessment
