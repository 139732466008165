import React, { useEffect, useState } from 'react'
import Subject_svg1 from './Subject_svgs/Subject-svg1'
import Sunject_svg2 from './Subject_svgs/Sunject_svg2'
import Subject_svg3 from './Subject_svgs/Subject_svg3'
import Subject_svg4 from './Subject_svgs/Subject_svg4'
import Subject_svg5 from './Subject_svgs/Subject_svg5'
import FAQ_svg1 from './Subject_svgs/FAQ_svg1'
import { Accordion } from 'react-bootstrap'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Blogs from './Blogs'
import Layout8 from './Layout8'
import Navbar from '../Navbar'
import Scrolltotop from '../../Scrolltotop'
import FAQ from './FAQ'
import What_will_you_be from '../What_will_you_be'
import Assessment from '../Assessment'
import Benefits_to_student from '../Benefits_to_student'
import { subjects } from '../../Contents/Subjects'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Subject_page = () => {

  const navigate=useNavigate()

  const {slug}=useParams()

  const[subject_data,setsubject_data]=useState({})

  useEffect(()=>{
    const data=subjects.find(x=>x.slug===slug)
    console.log("Subject details",data)
    setsubject_data(data)
  },[])
  
  return (
    <div>
      <Helmet>
  <title>{subject_data.meta_title}</title>
    <meta name="description" content={subject_data.meta_description} />
    {/* <meta name="keywords" content="services, products, my website" /> */}
  </Helmet>
    <div className='animate__animated animate__fadeIn'>
      <div className='subject-div1 pb-5'>
        <Navbar value={"subject"} color={'#F4F2F8'}/>
        <div className="row container mx-auto container1">
            <div className="col-md-6 mt-md-4">
                <h1 className='main-text m-0 mt-3' style={{lineHeight:'100px'}}>{subject_data.mainhead}</h1>
                {/* <p className='main-text m-0' style={{lineHeight:'48px'}}>Are here </p> */}
                <p className='fs-5 mt-4 m-0'>{subject_data.maintext}</p>
                <button className='mt-3 btn text-white rounded-pill py-2 px-4' onClick={()=>navigate('/contact')} style={{backgroundColor:'#8236C8',fontSize:'13px'}}>Let's Discuss</button>
            </div>
            <div className="col-md-6 mt-md-4 text-center" style={{position:'relative'}}>
              <img src={require('../../images/page1/image.png')} style={{width:'90%'}} alt="" />

              <div className='subject-small-div1 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/student/icon (1).png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>2K+</p>
                  <p className='m-0'>Projects</p>
                </div>
              </div>

              <div className='subject-small-div2 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/page1/star.png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>2K+</p>
                  <p className='m-0'>Projects</p>
                </div>
              </div>

              <div className='subject-small-div3 bg-white d-flex align-items-center p-3 shadow' style={{position:'absolute'}}>
                <img src={require('../../images/student/icon3.png')} alt="" />
                <div className='ms-2'>
                  <p className='text-start fw-bold m-0'>Product Designer</p>
                  <p className='m-0'>5 Years</p>
                </div>
              </div>
            </div>
        </div>

        <div className="row m-0 mx-auto container">
          <h2 className='main-text fs-3 mb-4'>OUR EXPERTISE</h2>
       <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Vector.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>One-on-One Tutoring</p>
              <p className='text-secondary mt-2'>Get personalized attention with 100% focus.</p>
              </div>
        </div>
        <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Ui Design.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Flexible Learning Options</p>
              <p className='text-secondary mt-2'>Choose from online, offline, or hybrid sessions.</p>
              </div>
        </div>
        <div className="col-md-3">
       <div className="">
              <img src={require('../../images/student/Product.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Expert Guidance</p>
              <p className='text-secondary mt-2'>Learn from top-rated tutors committed to your success.</p>
              </div>
        </div>
       </div>
      </div>

      <div className='container pt-5'>
      <p className='fw-bolder fs-1 m-0 text-center mb-4'>{subject_data.name} Subject</p>
      <p className='text-secondary text-center'>{subject_data.content}</p>

      </div>

      {/* Layout2 */}
      <div className="row m-0 container mx-auto py-5 my-4 align-items-center">
        <div className="col-md-7 ps-md-5">
          <img src={require('../../images/icon 2/WhatsApp Image 2024-09-19 at 10.09.31 AM.jpeg')} style={{width:'90%',borderRadius:'30px'}}  alt="" />
        </div>
        <div className="col-md-5 pe-md-5">
          <img src={require('../../images/student/fixed-width fixed-height sec-.png')} alt="" />
          <p className='fw-bolder fs-1 m-0' style={{lineHeight:'48px'}}>{subject_data.head1}</p>
          <p className='text-secondary mt-4'>{subject_data.para1}
          </p>
        </div>
      </div>

      <div className="row container mx-auto m-0 pb-5 pt-2">
        <Benefits_to_student/>
      </div>

      {/* Layout3 */}
      <div className="row m-0 container mx-auto py-5 my-4 align-items-center">
        <div className="col-lg-6 pe-md-5 mb-5 mb-md-0">
        <p className='fw-bolder fs-1 m-0' style={{lineHeight:'50px'}}>{subject_data.head4}</p>
        <p className='text-secondary mt-4 pe-md-5'>{subject_data.para4}</p>
        {/* <div className='mt-5'>
            <button className='btn fw-medium text-white px-3 py-2' style={{backgroundColor:'#205CE1',fontSize:'14px'}}>Join Now</button>
            <button className='btn fw-medium px-3 py-2 ms-3' style={{border:'1px solid #205CE1',fontSize:'14px',color:'#205ce1'}}>Learn More</button>
          </div> */}
        </div>
        <div className="col-lg-6 mt-md-5 mt-lg-0">
         <div className="row m-0 justify-content-center">
         <div className="col-md-6">
       <div className="">
              <img src={require('../../images/student/Icon.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Over 15 million customers</p>
              <p className='text-secondary mt-2'>Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
              </div>
        </div>
        <div className="col-md-6">
       <div className="">
              <img src={require('../../images/student/Icon1.png')} height={40} alt="" />
              <p className='fw-medium m-0 fs-5 mt-3'>Licensed by Bank Wakanda</p>
              <p className='text-secondary mt-2'>Amet minim mollit non Exercitation veniam consequat sunt nostrud dummy content.</p>
              </div>
        </div>
         </div>
        </div>
      </div>

      {/* Layout4 */}
      <div className='py-5' style={{backgroundColor:'#f7f9ff'}}>
      <div className="row m-0 container mx-auto align-items-center">
<div className="col-md-6 mb-5">
<img src={require('../../images/icon 2/WhatsApp Image 2024-09-19 at 10.09.35 AM (3).jpeg')} style={{width:'90%',borderRadius:'35px'}} alt="" />
</div>
<div className="col-md-6 mb-5">
  <p className='fs-1 fw-bold'>{subject_data.head2}</p>
  <p className='text-secondary mt-4'>{subject_data.para2}</p>
</div>

<div className="col-md-6 mt-3 pe-md-5">
  <p className='fs-1 fw-bold'>{subject_data.head3}</p>
  <p className='text-secondary mt-4'>{subject_data.para3}</p>
</div>

<div className="col-md-6 mt-3">
<img src={require('../../images/icon 2/WhatsApp Image 2024-09-19 at 10.18.29 AM.jpeg')} style={{width:'90%',borderRadius:'35px'}} alt="" />
</div>
      </div>
      </div>

      {/* Layout5 */}
      <div className="row m-0 container mx-auto py-5 mt-md-5 align-items-center">
<div className="col-md-6">
<img src={require('../../images/icon 2/WhatsApp Image 2024-09-19 at 10.09.32 AM.jpeg')} style={{width:'90%',borderRadius:'35px'}} alt="" />
  </div>

<div className="col-md-6">
  <div className='d-flex align-items-start'>
    <img src={require('../../images/student/Logo.png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Interactive Learning Tools</p>
      <p className='mt-3'>Engage with hands-on materials to simplify difficult concepts and enhance understanding.</p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-4'>
    <img src={require('../../images/student/Logo (1).png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Real-Time Progress Updates</p>
      <p className='mt-3'>Track your academic growth with instant feedback and detailed progress reports.</p>
    </div>
  </div>
  <div className='d-flex align-items-start mt-4'>
    <img src={require('../../images/student/Logo (2).png')} alt="" />
    <div className='ms-4'>
      <p className='m-0 layout5-text'>Focused Subject Coverage</p>
      <p className='mt-3'>Master everything from core subjects to advanced topics with tailored, in-depth sessions.</p>
    </div>
  </div>
</div>
      </div>

      {/* Layout6 */}
      {/* <div style={{backgroundColor:'#EDEAFA'}} className='py-5'>
        <div className="row py-5 m-0 container mx-auto">
          <p className='fw-bold' style={{color:'#8236C8'}}>Quick examination</p>
          <p className='fs-1 fw-bold'>Approdable Packages</p>
          <p className='m-0' style={{color:'#737373'}}>Problems trying to resolve the conflict between</p>
          <p className='mb-5 pb-4' style={{color:'#737373'}}>the two major realms of Classical physics: Newtonian mechanics </p>

          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg1/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Training Courses</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg3/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Training Courses</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg4/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Lifetime access</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
            <div className='packages-div px-2 px-md-5 py-4 shadow'>
              <div className='d-flex align-items-center' style={{height:'30%',position:'relative'}}>
              <p className="m-0" style={{zIndex:6}}><Subject_svg5/></p>
              <p className='m-0 package-icon' style={{position:'absolute',zIndex:1}}><Sunject_svg2/></p>
              </div>

              <p className='fw-bold m-0 mt-4'>Expert instruction</p>
              <img src={require('../../images/student/fixed-width fixed-height sec-.png')} height={4} width={80} className='my-4' alt="" />
              <p>The gradual 
accumulation of 
information about </p>
            </div>
          </div>

        </div>
      </div> */}

<What_will_you_be/>

<Assessment/>
      {/* Layout7 */}
      <Blogs/>

      {/* Layout8 */}
      <Layout8/>

      {/* FAQ */}
      <FAQ/>

      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
    </div>
  )
}

export default Subject_page
