import React from 'react'
import Work_svg from '../How-it-works/works-svgs/Work_svg'
import Work_svg1 from '../How-it-works/works-svgs/Work_svg1'
import Work_svg2 from '../How-it-works/works-svgs/Work_svg2'
import { boards } from '../../Contents/Borads'
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'
import Main_footer from '../Footer'
import { Helmet } from 'react-helmet'

const All_boards = () => {
    const navigate=useNavigate()
    
  return (
    <div>
        <Helmet>
    <title>Merida Tuition Tutor: Expert Board Tuition for All Subjects</title>
      <meta name="description" content="Master any subject. Merida Tuition Tutor offers personalized online & offline board tuition for all boards. Get A+ results!" />
      {/* <meta name="keywords" content="services, products, my website" /> */}
    </Helmet>
    <div className='animate__animated animate__fadeIn'>
        <Navbar value={"boards"}/>
        <div className="row m-0 container mx-auto py-4">
        <h1 className='fs-1 fw-bolder text-center mb-4'>All Boards</h1>
        <p className=' text-center mb-5 pb-4'>Be it any board, our expert tutors adapt to each student’s unique needs. We ensure engaging, fun learning that helps students excel in any curriculum with confidence.</p>
        {boards.map((x,index)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-5" onClick={()=>{
                    navigate(`/board/${x.slug}`)
                }} style={{cursor:'pointer'}}>
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>{x.name}</p>
                <p className='mt-4 text-center'>{x.paragraph1 && x.paragraph1.slice(0,100)} ...</p>

            </div>
        </div>
            )
        })}
        

      </div>

      <Main_footer/>
    </div>
    </div>
  )
}

export default All_boards
