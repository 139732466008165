export const subjects=[
    {
        slug:"maths-tuition-centre",
        h1:"Master every mathematic concept",
        meta_title:"Expert Mathematics Tuition Near You | Merida Tuition Centre",
        meta_description:"Merida Tuition is your one-stop solution for expert math coaching in Bangalore. Our experienced math tutors offer personalized mathematics tuition tailored to your specific needs. Whether you're struggling with algebra, geometry, calculus, or any other math subject, our math tutorials can help you achieve academic success. Find the best math tutor near me and enroll today!",
        name:"Mathematics",
        mainhead:"Master Every Mathematical Concept",
        maintext:"Discover intuitive ways to solve problems and build a solid foundation in mathematics with expert guidance.",
        content:"Mathematics is a crucial part of your education and academic success. It also helps you develop problem-solving skills and think logically. At Merida Tuition Tutor, we understand that math can sometimes seem complicated and  even intimidating. That’s why our expert tutors are here to make math easy and enjoyable. They break down tricky topics so you can understand them better and feel confident solving problems. Plus, if you ever have questions or get stuck, we’re here to help anytime. With our flexible doubt-solving sessions, help is always just a question away. Discover how learning math can be an enjoyable and fulfilling experience.",
        head1:"Expert Tutors Making Math Fun",
        para1:"Our highly skilled tutors make math fun and less intimidating. With years of experience and a passion for teaching, they break down complex concepts into easy-to-understand lessons. Whether you're learning algebra, geometry, or calculus, our tutors make every topic engaging and simple to understand. We make learning math fun with simple methods, so you can easily grasp the concepts and actually enjoy solving problems. Our support helps you build strong math skills and boosts your confidence in tackling any problem. With us, math becomes something you look forward to, not something you avoid!",
        head2:"Personalized Learning for Every Student",
        para2:"We get that each student learns in their own unique way.That’s why we create math programs specifically for you. We start by looking at what you’re already good at and where you might need more practice. Next, we create a learning plan tailored to your individual needs. This ensures you get the individual support you need to do well. We break down the concepts into simple steps, helping you apply what you learn effectively. With our customized support, math becomes clearer and more enjoyable, helping you achieve your best.",
        head3:"Flexible Doubt-Solving Sessions",
        para3:"Got a question? No problem! We know that questions and doubts can pop up anytime. That's why we offer flexible doubt-solving sessions to provide support whenever you need it. Whether you're stuck on a specific problem or need clarification on a topic, our tutors are available to assist you. Our goal is to make sure you never feel stuck or frustrated with math. Through our ongoing help, you’ll always have the guidance you need to succeed.",
        head4:"Simple and Effective Tuition Programs",
        para4:`Our math tuition programs are designed to be clear and effective. We aim to make learning easy and enjoyable, so you can quickly understand new concepts and build a solid foundation. Our goal is to help you reach your academic goals with as little stress as possible and as much success as possible. With our straightforward teaching methods and supportive environment, math will become a subject you actually enjoy. 
Don’t let math stress you out. Join Merida Tuition Tutor today and experience a fun, supportive, and effective way to learn math. `
    },

    {
        slug:"physics-coaching",
        h1:"Master every mathematic concept",
        meta_title:"Expert Physics Tuition Near You | Top Physics Coaching",
        meta_description:"Looking for top-notch physics tuition in Bengaluru? Merida Tuition offers expert physics coaching tailored to your needs. Our experienced tutors near me provide personalized guidance and help you excel in physics. Enroll today and achieve your academic goals!",
        name:"Physics",
        mainhead:"Explore the World of Physics",
        maintext:"Grasp complex physics concepts with clear explanations and practical applications from knowledgeable tutors.",
        content:"From why a ball stays in the air to the rules that govern the universe, Physics helps us see how everything works around us. In school, mastering Physics is crucial because it builds problem-solving skills and enhances critical thinking. Although some topics may seem challenging, our expert tutors are here to make learning Physics easier. We ensure that every concept is explained clearly, so you never feel stuck or confused. With our support, you’ll grasp even the toughest topics with ease! ",
        head1:"Expert Tutors Make Physics Easy",
        para1:"Our tutors are specialists in Physics, dedicated to breaking down complex topics into simple, understandable lessons. They use proven methods to ensure that you not only learn but truly understand Physics concepts. Whether it’s Newton’s laws or quantum mechanics, our tutors explain everything in a way that’s easy to follow. Say goodbye to tough topics as Physics with us becomes fun and easy to understand.",
        head2:"Personalized Learning for Every Student",
        para2:"We customize our Physics lessons to meet your personal learning needs. Whether you need extra help with certain topics or want to explore advanced concepts, we adjust our teaching methods to match your pace. This personalized approach ensures that you get the most out of your lessons and achieve your academic goals.",
        head3:"Instant Support When You Need It",
        para3:"Got a question? Need quick help with a tricky topic? We’ve got you covered! Our dedicated tutors are always ready to provide quick and reliable assistance whenever you need it. Whether it's during your scheduled lessons or outside class hours, our team is always there to guide you through any challenges you face. We make sure no doubt goes unresolved, giving you the confidence to tackle every topic with ease.",
        head4:"Engaging Lessons for All Levels",
        para4:`Our Physics lessons are designed to be engaging and interactive.We use different methods to keep learning engaging and easy to understand. From visual aids to practical examples, our lessons are crafted to keep you interested and motivated. No matter your current level, our goal is to make Physics enjoyable and accessible to everyone.
Join Merida Tuition Tutor today and experience the best in Physics education. Our expert tutors are excited to help you achieve your goals and make learning Physics a fun adventure.
`
    },

    {
        slug:"chemistry-tuition",
        h1:"Master every mathematic concept",
        meta_title:"Best Chemistry Tutor Near Me | Expert Chemistry Tuition at Merida Tuition",
        meta_description:"Discover the best chemistry tutor in Bengaluru! At Merida Tuition, our expert tutors provide personalized guidance and support to help you excel in chemistry. Whether you're struggling with basic concepts or aiming for top scores, we have the perfect chemistry tuition near me to suit your needs. Join our chemistry tutoring center and unlock your potential in chemistry.",
        name:"Chemistry",
        mainhead:"Uncover Chemistry’s Core Principles",
        maintext:"Learn chemistry through hands-on experiments and easy-to-understand theories for a thorough grasp of the subject.",
        content:"Chemistry explores how materials interact and transform, playing a crucial role in our daily experiences. From the air we breathe to the food we eat, everything involves chemical reactions.. Understanding Chemistry gives students insight into how the world operates on a molecular level, helping them develop critical thinking and analytical skills. It’s a core subject in school and highly important for academic growth and future studies. We know that certain topics can be challenging, and that’s where our expert tutors come in to make learning Chemistry both easy and enjoyable.",
        head1:"Expert Tutors for Every Topic",
        para1:"At Merida Tuition Tutor, our Chemistry tutors are experts who simplify even the most complex topics. Whether it’s balancing chemical equations or understanding atomic structures, our tutors break down each topic step by step. We ensure that every student grasps the fundamentals and builds a strong foundation. With us, Chemistry becomes easy, enjoyable, and much less intimidating!",
        head2:"Personalized Doubt Solving",
        para2:"We know that doubts can arise at any moment, especially in a subject like Chemistry. That’s why we offer personalized doubt-solving sessions, ensuring no student feels stuck. Our tutors are always available to clear up confusions, answer questions, and explain tricky topics in the simplest way possible. We believe every student deserves clear, easy-to-understand answers whenever they need them.",
        head3:"Simplifying Tough Concepts",
        para3:"Topics like chemical bonding or organic reactions can feel overwhelming. But we make sure that even the toughest concepts are simplified. Our tutors explain each idea with clarity and patience, helping students understand the ‘why’ behind every reaction. No topic is too hard when you learn with us!",
        head4:"Continuous Support and Guidance",
        para4:`Learning doesn't stop after class. We provide continuous support and guidance to ensure students are always on track. Whether it's homework help or last-minute exam prep, we’re here for you every step of the way. Our goal is to make sure every student gains confidence in Chemistry and excels in their academics.
Start your Chemistry journey with Merida Tuition Tutor today! Our approachable, skilled tutors will support you through every stage of your learning journey.`
    },

    {
        slug:"biology-tuition",
        h1:"Master every mathematic concept",
        meta_title:"Biology Tuition Near Me | Expert Biology Tutors | Merida Tuition",
        meta_description:"Merida Tuition is your one-stop solution for expert math coaching in Bangalore. Our experienced math tutors offer personalized mathematics tuition tailored to your specific needs. Whether you're struggling with algebra, geometry, calculus, or any other math subject, our math tutorials can help you achieve academic success. Find the best math tutor near me and enroll today!Looking for expert biology tuition in Bengaluru? Merida Tuition offers comprehensive online courses for biology, taught by experienced tutors. Our engaging lessons and personalized guidance help students excel in their studies. Learn more about our biology tuition programs and find a biology tutor near you.",
        name:"Biology",
        mainhead:"Explore Life’s Wonders Through Biology",
        maintext:"Dive into the study of life and organisms with engaging lessons and insightful explanations that make biology come alive.",
        content:"Biology, the study of life and living organisms, is a fundamental subject in school that opens the door to understanding how life functions and evolves. Whether you dream of a career in medicine, research, or environmental science, a strong foundation in biology is crucial. Not only does it help in academics, but it also builds critical thinking skills and prepares you for future studies. Some concepts in biology can be complex, but with the expert guidance of our tutors, every topic becomes clear and manageable. We’re here to ensure you grasp even the most challenging ideas with ease.",
        head1:"Understanding the Basics",
        para1:"Biology covers a range of topics from the simplest cells to complex ecosystems, and it can seem overwhelming at first. Our tutors are here to make everything easy to understand. We start with the basics and build your knowledge gradually, step by step. This method ensures that you understand each concept before moving on to the next. By linking new information to what you already know, we make learning biology clear and manageable. Our approach helps you build a strong foundation, making it easier to grasp more complex topics later.",
        head2:"Mastering Complex Biological Topics",
        para2:"Some parts of biology, like genetics or how cells work, can be confusing. Our tutors are here to make these tough topics easier. We explain things in simple terms with real-life examples to help you understand better. You’ll get one-on-one support, so you can ask questions and get clear answers. With our help, even the hardest parts of biology will start to make sense, and you’ll feel more confident in your studies. We’re here to make learning biology as easy and enjoyable as possible.",
        head3:"Expert Help Whenever You Need",
        para3:"Learning biology doesn’t end with the lesson. Our team offers continuous support to ensure you always feel confident and on track. With doubt-solving sessions and personalized one-on-one meetings, we make sure all your questions are answered. Whether you need help with a tricky topic or just need some extra clarification, our tutors are always available to assist you. This ongoing support helps you stay motivated and ensures that no concept remains unclear.",
        head4:"Why Choose Our Tutors",
        para4:`Our biology tutors are passionate educators dedicated to making learning enjoyable. By incorporating real-life examples and interactive tools, they help you grasp complex concepts  and remember what you’re learning. This way, each student receives the focused support they need.  Whether you’re struggling with a specific topic or just looking to deepen your understanding, our tutors are committed to giving you personalized support, so you get the help you need when you need it. 
Join us at Merida Tuition Tutor for easy-to-follow lessons and supportive guidance. Let's make biology fun and understandable together!`
    },

    {
        slug:"english-tuition",
        h1:"Master every mathematic concept",
        meta_title:"English Tuition Near Me | Expert English Tutors - Merida Tuition",
        meta_description:"Looking for expert English tuition near you? At Merida Tuition, we offer personalized English lessons by experienced tutors. Find the best English tutor near you today!",
        name:"English",
        mainhead:"Master English with Interactive Practice",
        maintext:"Enhance your reading, writing, and speaking skills in English with personalized lessons and interactive practice sessions.",
        content:"A strong grasp of English enhances your ability to communicate effectively, which is essential for higher studies and professional growth. Proficiency in English helps you articulate your thoughts clearly, making you appear more confident and smart. However, English grammar and writing can sometimes be challenging. At Merida Tuition Tutor, our expert tutors simplify complex topics and provide personalized support. Whether you're struggling with grammar rules or need help with writing, our tutors are here to guide you every step of the way.",
        head1:"Understand Complex Grammar",
        para1:"Grammar can often be confusing, with rules and exceptions that seem endless. Our tutors simplify these rules into easy, manageable steps. We focus on common problem areas such as tense usage, subject-verb agreement, and sentence structure. By using easy-to-follow examples and practice exercises, we ensure that even the toughest grammar concepts become clear. With personalized guidance, you'll build a strong foundation in grammar, enhancing your writing and speaking skills.",
        head2:"Write Like a Pro",
        para2:"Writing is an essential skill for academic success and in general as well. We focus on developing your writing abilities through engaging and practical exercises. Our tutors teach you how to structure essays, create compelling arguments, and use correct grammar and style. We give feedback on your writing to support your ongoing improvement. With personalized guidance, you’ll learn how to express your ideas clearly and effectively, making your writing stand out.",
        head3:"Master Reading with Confidence",
        para3:"Improve your reading comprehension with our targeted strategies. Our lessons include strategies to improve your reading skills, such as identifying main ideas, understanding context, and analyzing texts. We help you develop techniques to read more efficiently and comprehend various types of content, from literature to academic texts. By enhancing your reading skills, you’ll perform better in exams and enjoy a broader range of literature.",
        head4:"Personalized Help for Every Question",
        para4:`We understand that doubts can arise at any time during your learning process. Our tutors are here to answer your questions and clear up any doubts. We offer personalized doubt-solving sessions to ensure you don’t feel stuck. Our goal is to make sure you fully understand each concept before moving on. With our support, you can learn at your own pace and build a strong foundation in English.
Enhance your English skills with our expert support. Schedule your first session today and begin your journey to mastering English!
`
    },

    {
        slug:"hindi-tuition",
        h1:"Master every mathematic concept",
        meta_title:"Hindi Tuition Near Me | Expert Hindi Tutors | Merida Tuition",
        meta_description:"Looking for professional Hindi tuition? Merida Tuition offers personalized lessons with experienced Hindi tutors near you. Improve your language skills with flexible and tailored Hindi tutoring sessions!",
        name:"Hindi",
        mainhead:"Master English with Interactive Practice",
        maintext:"Enhance your reading, writing, and speaking skills in English with personalized lessons and interactive practice sessions.",
        content:"Hindi plays a vital role in Indian culture and education. Learning Hindi can make a huge difference in your academic journey by improving reading and writing skills. It’s also a fantastic way to connect with people all over India and enjoy Indian movies, books, and music. Plus, being good at Hindi can open up better job opportunities in the future. At Merida Tuition Tutor, we make learning Hindi exciting and enjoyable.",
        head1:"Master Every Aspect of the Language",
        para1:"We cover every aspect of Hindi to help you excel. From reading and writing to understanding poetry and essays, our lessons are designed to be clear and engaging. We’re here to ensure that learning Hindi is not only effective but also enjoyable, so you can feel ready and excited for your classes. Whether you’re preparing for exams or just want to improve your Hindi skills, we make learning effective and engaging.",
        head2:"Clear and Easy Grammar Lessons",
        para2:"Grammar doesn’t have to be boring! Our expert tutors turn tricky Hindi grammar into something fun and easy. We break down the rules into simple lessons, using clear examples and interactive activities. From understanding sentence structure to getting comfortable with verbs and tenses, we make learning grammar effortless. With our help, you’ll quickly become comfortable with Hindi grammar and use it confidently in your daily life.",
        head3:"Personalized Sessions Just for You",
        para3:"Our tutors work closely with you to tailor each session to your needs and interests. Whether you need extra help with a tricky topic or want to dive deeper into something you love, we’ve got you covered. We use easy-to-understand explanations and fun activities to keep you engaged. Our goal is to make sure you feel confident and excited about learning. With our personalized approach, you’ll get the support you need to succeed and truly enjoy mastering Hindi.",
        head4:"Get All Your Questions Answered",
        para4:`Got questions or feeling stuck with Hindi? No worries at all! Our tutors provide personalized one-on-one support to assist you every step of the way. Whether you’re struggling with a specific topic or just need a bit more practice, we’re always ready to step in. We provide clear and simple explanations to make sure you understand every concept. If you need extra practice or find certain topics tricky, we’re here to assist you. Our goal is to ensure you never feel lost and always have the guidance you need to succeed. With our help, you’ll feel confident and well-supported every step of the way. 
Start your Hindi learning journey with Merida Tuition Tutor today and see the difference. Whether you're looking to improve your reading, writing, or just want to enjoy the language more, we're here to help.
`
    },

    {
        slug:"sanskrit-tuition",
        h1:"Master every mathematic concept",
        meta_title:"Sanskrit Tuition Near Me | Expert Sanskrit Tutors Online | Merida Tuition",
        meta_description:"Looking for Sanskrit tuition? Merida Tuition offers expert Sanskrit tutors near you and online. Join personalized Sanskrit classes to master the language effectively!",
        name:"Sanskrit",
        content:"Sanskrit is the foundation of many modern Indian languages and plays a crucial role in understanding ancient texts and scriptures. Whether you want to excel in school or simply learn something new, Sanskrit is important.  Learning Sanskrit  also strengthens the understanding of other languages due to its structured grammatical framework. At Merida Tuition Tutor, our expert tutors make it easy for you to understand even the toughest topics. We break down each lesson into simple steps, helping you learn with ease. If you ever have doubts, we’re always ready to help, so you never feel lost. Our goal is to make Sanskrit fun and easy to learn for everyone, no matter where you start.",
        head1:"Expert Guidance for Total Beginners",
        para1:"Learning Sanskrit might seem challenging, especially for beginners, but our team of experienced tutors is here to make the process smooth. With years of experience in teaching Sanskrit, our tutors focus on making every topic clear and easy to understand. From basic sentence structures to complex grammar rules, we break it down in a way that’s relatable. Plus, we offer continuous doubt-solving sessions, so you never feel stuck at any point during your learning journey.",
        head2:"Addressing Every Topic",
        para2:"At Merida Tuition Tutor, we believe that every aspect of the subject should be understood deeply, and that includes Sanskrit. Whether it’s learning about shlokas, grammar, or understanding the complexities of sentence formation, we ensure all topics are covered thoroughly. Our tutors follow a step-by-step approach, using examples and simple explanations, so that each student, regardless of their level, can understand the lessons fully. No topic is too tough when taught in the right way!",
        head3:"Making Complex Grammar Simple",
        para3:"Sanskrit grammar is made simple through our tutoring. We help every student understand the basics of grammar, whether it’s word forms, verb changes, or making sentences, without feeling confused. With tailored lessons and regular practice, students will find even the most challenging topics easy to learn and understand. We ensure students not only learn but also retain their understanding for academic success.",
        head4:"Quick and Easy Doubt-Solving",
        para4:`We know that learning a subject like Sanskrit can bring up plenty of doubts, but that’s where we shine. Our dedicated doubt-solving sessions ensure that you never get left behind or confused. No question is too small, and our tutors are patient, making sure every concept is clear before moving forward. With Merida Tuition Tutor, you will never feel stuck or unsure. We make Sanskrit learning easy, fun, and engaging for everyone.

Join Merida Tuition Tutor today and experience how simple and enjoyable learning this ancient subject can be!
`
    },

    {
        slug:"social-studies-tuition",
        h1:"Master every mathematic concept",
        meta_title:"Social Science Tuition Near Me | Expert Social Studies Tutors | Merida Tuition",
        meta_description:"Looking for expert social science tuition near you? Merida Tuition provides personalized SST tutoring with experienced tutors to help you excel in social studies. Get started today!",
        name:"Social Studies",
        content:"Social Studies is a crucial subject that helps students understand how societies have developed and how they function today. Learning Social Studies also enhances general knowledge and prepares students for competitive exams where historical and geographical knowledge is essential. At Merida Tuition Tutor, we make Social Studies easy to grasp with expert tutors who simplify every topic. Our goal is to make learning engaging and clear, so students can excel and stay curious about the world.",
        head1:"Why Social Studies Matters",
        para1:"Social Studies covers important topics like history, geography, and cultural studies, helping students learn about past events and their impacts on today’s society. This subject broadens students’ perspectives and helps them become more informed about current global issues. By grasping these concepts, students can perform better in school and feel more connected to the world. Our tutors make these topics engaging and straightforward, so students can easily relate and remember what they learn.",
        head2:"Expert Guidance for Every Topic",
        para2:"At Merida Tuition Tutor, our skilled tutors are dedicated to making Social Studies easy to understand. We break down complex topics, like historical events and geographical concepts, into simple, manageable parts. Each lesson is designed to be engaging, using real-life examples to help students grasp difficult ideas. This approach ensures that every student, no matter their level, can understand and enjoy the subject. With our help, students will build a solid foundation and feel confident in their Social Studies knowledge.",
        head3:"Doubt-Solving and Support",
        para3:"We know that Social Studies can sometimes be challenging, and we’re here to help. Our tutors provide personalized support to address any questions or difficulties students may have. We offer doubt-solving sessions that focus on clarifying concepts and providing additional practice. This support ensures that students never feel stuck and can fully understand every topic. By making sure all doubts are addressed promptly, we help students stay on track and succeed in their studies.",
        head4:"Making Learning Fun and Memorable",
        para4:`Learning Social Studies doesn’t have to be boring. At Merida Tuition Tutor, we use creative methods to make lessons enjoyable and memorable. With interactive activities and relatable examples we help students connect with the material better. This method makes learning enjoyable and also helps students remember information more effectively. Our goal is to make Social Studies interesting and fun, so students look forward to their lessons and gain a deeper understanding of the subject.Join Merida Tuition Tutor for an engaging and enjoyable Social Studies experience with expert guidance. Our expert tutors will help you master the subject and gain a deeper understanding.`
    },

    {
        slug:"history-tuition",
        h1:"Master every mathematic concept",
        meta_title:"History Tuition Near Me | Expert History Tutors | Merida Tuition",
        meta_description:"Looking for professional history tuition near you? Merida Tuition offers personalized history tutorials with experienced tutors to help you excel. Find the best history tutor today!",
        name:"History",
        content:"History goes beyond just remembering dates and events. It’s about understanding how our past impacts the present and influences the future. When you study history, you learn about different cultures, societies, and important events that have shaped our world. This not only helps you understand the world better but also prepares you for exams that include history questions. At Merida Tuition Tutor, our history tutors make learning easy and enjoyable. They provide clear explanations and personal support to help you grasp even the most challenging topics. Join us and see how history can be both simple and fascinating, while boosting your academic performance.",
        head1:"Explore the Depths of History",
        para1:"Understanding history is crucial for students as it connects past events to present-day issues. It helps build a foundation for critical thinking and improves general knowledge. Learning about different cultures and historical events allows students to see how societies evolve and how past decisions impact today’s world. Our history lessons at Merida Tuition Tutor are designed to make these connections clear and engaging, so students can excel academically and in exams that test their understanding of historical facts.",
        head2:"Personalized Learning for You",
        para2:"At Merida Tuition Tutor, we offer personalized history sessions tailored to your needs. Our tutors work with you to identify your strengths and areas for improvement. They create customized lessons to help you understand complex topics better and at your own pace. This focused approach ensures that you get the most out of your history lessons and build a solid foundation for your studies.",
        head3:"Meet Your History Guides",
        para3:"Our history tutors at Merida Tuition Tutor are passionate about teaching and making history come alive. They use engaging methods to break down complex historical topics, making them easy to understand. With their guidance, students will enjoy learning about historical events, figures, and concepts. Our tutors are dedicated to helping each student succeed, ensuring that every lesson is both informative and enjoyable. Whether you need help with tricky topics or want to enhance your knowledge, our experts are here for you.",
        head4:"Support When You Need It",
        para4:`At Merida Tuition Tutor, we know that history can sometimes be challenging. That’s why we offer plenty of support to make sure you’re never stuck. Our one-on-one sessions are perfect for clearing up any confusion and getting personalized help. We provide easy-to-understand materials and answers to all your questions. With our support, you’ll find that history becomes much easier and more enjoyable. Join us, and we’ll make sure you’re confident in your history studies.

Enhance your knowledge in History and start learning today with Merida Tuition Tutor. Our top tutors provide clear and effective lessons to help you understand better.
`
    },

]
