import React from 'react'
import Work_svg from '../How-it-works/works-svgs/Work_svg'
import Work_svg1 from '../How-it-works/works-svgs/Work_svg1'
import Work_svg2 from '../How-it-works/works-svgs/Work_svg2'
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'
import Main_footer from '../Footer'
import { activities } from '../../Contents/Activities'
import { Helmet } from 'react-helmet'

const All_activities = () => {
    const navigate=useNavigate()
    
  return (
    <div>
        <Helmet>
    <title>Boost Your Skills! All Curricular Activity Tutoring at Merida Tuition Tutor</title>
      <meta name="description" content="Merida Tuition Tutor offers expert tutoring for ALL curricular activities. Enhance your child's learning and academic performance across all subjects. Get started today!" />
      {/* <meta name="keywords" content="services, products, my website" /> */}
    </Helmet>
    <div className='animate__animated animate__fadeIn'>
        <Navbar value={"activities"}/>
        <div className="row m-0 container mx-auto py-4">
        <h1 className='fs-1 fw-bolder text-center mb-4'>All Curricular Activities</h1>
        <p className=' text-center mb-5 pb-4'>Sed in cursus sem. Proin malesuada neque quis dictum dignissim. Maecenas vitae felis diam. Praesent pellentesque suscipit commodo. Fusce in pretium dolor. Proin eget nibh accumsan, placerat erat sit amet, pellentesque nisi.</p>
        {activities.map((x,index)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-5" onClick={()=>{
                    navigate(`/curricular_activity/${x.slug}`)
                }} style={{cursor:'pointer'}}>
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>{x.name}</p>
                <p className='mt-4 text-center'>{x.content && x.content.slice(0,100)} ...</p>

            </div>
        </div>
            )
        })}
        

      </div>

      <Main_footer/>
    </div>
    </div>
  )
}

export default All_activities
