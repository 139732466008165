import React, { useEffect, useState } from 'react'
import Blogs from '../Subjects/Blogs'
import Benefits_to_student from '../Benefits_to_student'
import What_will_you_be from '../What_will_you_be'
import Assessment from '../Assessment'
import Layout8 from '../Subjects/Layout8'
import FAQ from '../Subjects/FAQ'
import Main_footer from '../Footer'
import Backtotop from '../Backtotop'
import Navbar from '../Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import { activities } from '../../Contents/Activities'
import { Helmet } from 'react-helmet'

const Curricular_activity = () => {
  const navigate = useNavigate()

  const {slug}=useParams()

  const[activity_data,setactivity_data]=useState({})

  useEffect(()=>{
    const data=activities.find(x=>x.slug===slug)
    console.log("Board details",data)
    setactivity_data(data)
  },[])

  return (
    <div>
      <Helmet>
      <title>{activity_data.meta_title}</title>
        <meta name="description" content={activity_data.meta_description} />
        {/* <meta name="keywords" content="services, products, my website" /> */}
      </Helmet>

      <div className='animate__animated animate__fadeIn' style={{backgroundColor:'#E2E8F0'}}>
      <div className='activity-div pb-5' style={{position:'relative'}}>
        <Navbar value={"activity"}/>
        <h1 className='activity-text container fw-bold mt-5'>Unlock New Skills Learn, Play, and Grow</h1>

        <p className='text-center text-white fs-5 container mt-4'>Sit elit feugiat turpis sed integer integer accumsan turpis. Sed suspendisse nec lorem mauris.
        Pharetra, eu imperdiet ipsum ultrices amet, dui sit suspendisse.</p>
        <button className='btn text-white px-3 py-2 d-block mx-auto' style={{backgroundColor:'#347EE8'}}>Connect Now</button>

        <img src={require('../../images/WhatsApp Image 2024-09-20 at 12.00.38 PM.jpeg')} style={{width:'70%',position:'absolute',left: '50%', transform: 'translateX(-50%)',bottom:'-16%',borderRadius:'20px'}} alt="" />
      </div>


      {/* Layout2 */}
      <div className="row m-0 container mx-auto align-items-center pb-5 mb-4" style={{paddingTop:'200px'}}>
      <p className='container text-center pb-5 fw-bold'>"{activity_data.content}"</p>
        <div className="col-lg-6">
            <img src={require('../../images/Left4.png')} style={{width:'90%'}} alt="" />
        </div>

        <div className="col-lg-6">
            <p className='online-text fw-bold' style={{lineHeight:'54px'}}>{activity_data.head1}</p>
            <p className='fw-medium mt-4 pt-2'>{activity_data.para1}</p>

{/* <p className='d-flex align-items-center fw-bold mt-5' style={{color:'#2563EB',cursor:'pointer'}}>Explore teachers and students<span className='ms-3'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></p> */}
        </div>
      </div>

      <Benefits_to_student/>

      {/* Layout 4 */}
      <div className="row container px-md-5 align-items-center mx-auto activity-layout4">
        <div className="col-lg-6 ms-auto">
            <p className='fw-bold text-white' style={{fontSize:'60px',lineHeight:'60px'}}>{activity_data.head2}</p>
            <p className='text-white mt-4 pt-3' style={{fontSize:'17px'}}>{activity_data.para2}</p>
            
            {/* <p className='d-flex align-items-center fw-bold mt-5' style={{color:'#ffff',cursor:'pointer'}}>Learn more<span className='ms-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></p> */}
        </div>
      </div>

      {/* Layout 5 */}
      <div className="row m-0 container mx-auto align-items-start mb-4" style={{paddingTop:'100px',paddingBottom:'50px'}}>
        <div className="col-lg-6 pe-md-5">
            <p className='online-text fw-bold' style={{lineHeight:'54px'}}>{activity_data.head3}</p>
            <p className='fw-medium mt-4 pt-2'>{activity_data.para3}</p>

{/* <p className='d-flex align-items-center fw-bold mt-5' style={{color:'#2563EB',cursor:'pointer'}}>Explore teachers and students<span className='ms-3'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></p> */}
        </div>
        <div className="col-lg-6 text-center">
            <img src={require('../../images/activity-img.jpeg')} style={{width:'90%',borderRadius:'25px'}} alt="" />
        </div>
      </div>

      <div className="row m-0 container mx-auto align-items-start mb-4" style={{paddingTop:'50px',paddingBottom:'100px'}}>
      <div className="col-lg-6">
            <img src={require('../../images/WhatsApp Image 2024-09-20 at 12.00.37 PM (1).jpeg')} style={{width:'90%',borderRadius:'25px'}} alt="" />
        </div>

        <div className="col-lg-6 ps-md-5">
            <p className='online-text fw-bold' style={{lineHeight:'54px'}}>{activity_data.head4}</p>
            <p className='fw-medium mt-4 pt-2'>{activity_data.para4}</p>
        </div>
      </div>

      {/* Layout 6 */}
      <div className="activity-layout6 container mx-auto px-md-5 mb-5">
        <p className="activity-text2 m-0">
        Join Our Classes Today – Discover a New You!
        </p>
        <p className="text-white text-center">
        Egestas fringilla aliquam leo, habitasse arcu varius lorem elit. Neque pellentesque donec et tellus ac varius tortor, bibendum. Nulla felis ac turpis at amet. Purus malesuada placerat arcu at enim elit in accumsan.
        </p>

        <button onClick={()=>{
          navigate('/contact')
        }} className='btn btn-primary px-4 mt-4 py-2 d-block mx-auto d-flex align-items-center'>Contact Us<span className='ms-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></span></button>
      </div>

      <div className='bg-white'>
      <What_will_you_be/>
      <Assessment/>
      </div>
      <Blogs/>  
      <Layout8/>
      <div className="bg-white">
      <FAQ/>
      </div>
      <Main_footer/>
      <Backtotop/>
    </div>
    </div>
  )
}

export default Curricular_activity
