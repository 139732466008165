import React from 'react'
import FAQ_svg1 from './Subject_svgs/FAQ_svg1'
import { Accordion } from 'react-bootstrap'

const FAQ = () => {
  return (
    <div>
      <div className='py-5'>
        <div className="row m-0 container mx-auto py-5">
          {/* <div className="col-lg-5">
            <p className='fw-medium' style={{fontSize:'14px',color:'#8236C8'}}>FAQ</p>
            <p className="fw-bold fs-1 m-0" style={{lineHeight:'50px'}}>
            Frequently Asked Questions
            </p>

            <div className='d-flex align-items-center px-2 px-md-3 py-3 mt-5' style={{backgroundColor:'#EEEEFB'}}>
              <FAQ_svg1/>
              <div className='ms-3'>
                <p className='fw-bold m-0'>Contact Live Chat Support</p>
                <p className='text-secondary m-0'>24/7 Available No Chatbots</p>
              </div>
            </div>
            <div className='d-flex align-items-center px-2 px-md-3 py-3 mt-3' style={{backgroundColor:'#EEEEFB'}}>
              <FAQ_svg1/>
              <div className='ms-3'>
                <p className='fw-bold m-0'>Contact Live Chat Support</p>
                <p className='text-secondary m-0'>24/7 Available No Chatbots</p>
              </div>
            </div>
            <div className='d-flex align-items-center px-2 px-md-3 py-3 mt-3' style={{backgroundColor:'#EEEEFB'}}>
              <FAQ_svg1/>
              <div className='ms-3'>
                <p className='fw-bold m-0'>Contact Live Chat Support</p>
                <p className='text-secondary m-0'>24/7 Available No Chatbots</p>
              </div>
            </div>
          </div> */}

          <div className="col-12 ps-md-4">
          <p className='fw-medium' style={{fontSize:'14px',color:'#8236C8'}}>FAQ</p>
            <h6 className="fw-bold fs-1 m-0 mb-5" style={{lineHeight:'50px'}}>
            Frequently Asked Questions
            </h6>
          <Accordion className='bg-transparent'>
      <Accordion.Item eventKey="0" className='accordion-item'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>What is Merida Tuition Tutor?</p></Accordion.Header>
        <Accordion.Body>
        Merida Tuition Tutor is a tuition platform providing personalized tutoring for various subjects, including core academics and extracurriculars, through flexible learning modes.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>What services does Merida Tuition Tutor offer?
        </p></Accordion.Header>
        <Accordion.Body>
        We offer in-person, online, and hybrid tutoring for school subjects, programming, languages, and co-curricular activities for all education boards.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>What makes Merida Tuition Tutor different from others?</p></Accordion.Header>
        <Accordion.Body>
        We focus on tailored learning, progress tracking, flexible scheduling, and expert tutors, all at affordable prices to ensure student success.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>How do tutors at Merida Tuition Tutor personalize learning?</p></Accordion.Header>
        <Accordion.Body>
        Our tutors assess each student’s needs and create a tailored learning plan, ensuring each session addresses the student’s specific challenges and goals.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'> Which education boards and subjects does Merida Tuition Tutor cover?</p></Accordion.Header>
        <Accordion.Body>
        We teach all the top boards like CBSE, ICSE, IB, and State boards, covering a wide range of subjects to help students succeed!
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'> How can I become a tutor at Merida Tuition Tutor?
        </p></Accordion.Header>
        <Accordion.Body>
        You can register on our website, complete your profile, and start applying for tutoring opportunities in your area of expertise.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>How do I start teaching after registration?</p></Accordion.Header>
        <Accordion.Body>
        Once your profile is verified, you can begin teaching by applying for jobs or conducting demo sessions to connect with students.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>How are tutoring sessions scheduled?
        </p></Accordion.Header>
        <Accordion.Body>
        Tutoring sessions are flexible and scheduled based on your availability and the student's preferences, ensuring convenience for both parties.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>Will I receive feedback on my performance as a tutor?</p></Accordion.Header>
        <Accordion.Body>
        Yes, we provide continuous feedback to help you improve your tutoring methods and ensure the best learning experience for students.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>How can I find the right tutor for my needs?

        </p></Accordion.Header>
        <Accordion.Body>
        We match you with a tutor based on your subject needs, learning style, and preferences, ensuring the best fit for your academic goals.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>Can I choose between online and in-person classes?</p></Accordion.Header>
        <Accordion.Body>
        You totally can! Start choosing from online, in-person, or hybrid tutoring sessions, depending on what suits you best.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>How does the learning process work?</p></Accordion.Header>
        <Accordion.Body>
        Our tutors create personalized learning plans and offer one-on-one or small group sessions that fit your schedule and help you grasp concepts at your pace.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4" className='accordion-item rounded-0'>
        <Accordion.Header className='accordion-header'><p className='m-0 fw-bold'>What subjects and activities are offered?</p></Accordion.Header>
        <Accordion.Body>
        We cover all major school subjects and extracurriculars like language courses, programming, and more, ensuring a well-rounded education.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
