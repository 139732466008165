import React from 'react'
import Work_svg from '../How-it-works/works-svgs/Work_svg'
import Work_svg1 from '../How-it-works/works-svgs/Work_svg1'
import Work_svg2 from '../How-it-works/works-svgs/Work_svg2'
import { useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'
import Main_footer from '../Footer'
import { languages } from '../../Contents/Languages'
import { subjects } from '../../Contents/Subjects'
import { Helmet } from 'react-helmet'

const All_subjects = () => {
    const navigate=useNavigate()
    
  return (
    <div>
        <Helmet>
    <title>Master Any Subject: All Subjects Online & Offline Tuition</title>
      <meta name="description" content="Conquer academics with expert-led all-subjects tuition. Merida Tuition offers personalized online & offline classes to empower students of all ages. Get started today!" />
      {/* <meta name="keywords" content="services, products, my website" /> */}
    </Helmet>
    <div className='animate__animated animate__fadeIn'>
        <Navbar value={"subject"}/>
        <div className="row m-0 container mx-auto py-4">
        <h1 className='fs-1 fw-bolder text-center mb-4'>All Subjects</h1>
        <p className=' text-center mb-5 pb-4'>We offer engaging and easy-to-follow lessons across all topics, making learning enjoyable and helping you excel in every subject.</p>
        {subjects.map((x,index)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-5" onClick={()=>{
                    navigate(`/subject/${x.slug}`)
                }} style={{cursor:'pointer'}}>
            <div className='lang-div shadow'>
                <div className="text-center icons">
                    <Work_svg/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>{x.name}</p>
                <p className='mt-4 text-center'>{x.content && x.content.slice(0,150)}...</p>

            </div>
        </div>
            )
        })}
        

      </div>

      <Main_footer/>
    </div>
    </div>
  )
}

export default All_subjects
