import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import Main_footer from './Footer'
import Backtotop from './Backtotop'
import Scrolltotop from '../Scrolltotop'
import Main_svg1 from '../svg/Main_svg1'
import Main_svg2 from '../svg/Main_svg2'
import Main_svg3 from '../svg/Main_svg3'
import Main_svg4 from '../svg/Main_svg4'
import Main_svg5 from '../svg/Main_svg5'
import Main_svg6 from '../svg/Main_svg6'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Assessment from './Assessment'
import What_will_you_be from './What_will_you_be'
import Benefits_to_student from './Benefits_to_student'
import { languages } from '../Contents/Languages'
import { subjects } from '../Contents/Subjects'
import { boards } from '../Contents/Borads'

const Main_page = () => {

    const [captcha, setCaptcha] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(false);
  
    // Function to generate random CAPTCHA
    const generateCaptcha = () => {
      let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let captchaText = '';
      for (let i = 0; i < 6; i++) {
        captchaText += chars[Math.floor(Math.random() * chars.length)];
      }
      setCaptcha(captchaText);
      setInputValue(''); // Clear input when new captcha is generated
      setIsValid(false); // Reset validation status
    };
  
    // Validate CAPTCHA
    const validateCaptcha = () => {
      if (inputValue === captcha) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    };

    useEffect(()=>{
        generateCaptcha()
    },[])


    const navigate=useNavigate()

    const[state,setstate]=useState('language')

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className='custom-tooltip border rounded'>
          {props}
        </Tooltip>
      );

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='main-div'>
        <Navbar value={"home"} color={'rgb(121,6,210)'}/>
       <div className='row m-0 container container1 mx-auto main-div1 align-items-center'>
        <div className="col-lg-6">
        <p className='m-0' style={{lineHeight:'70px'}}><span className='main-text' style={{color:'#4EB1F8'}}>TEACH</span><span className='main-text ms-3 text-white'>BRIGHT MINDS</span></p>
        <p className='m-0' style={{lineHeight:'70px'}}><span className='main-text' style={{color:'#4EB1F8'}}>SHAPE</span><span className='main-text ms-3 text-white'>FUTURES</span></p>
        <p className='fs-5 text-white mt-3'>Join us and bring out the best in every student. Flexible schedules, meaningful work, and a chance to shape young minds.</p>

        <div className='mt-4 pt-2'>
            <button className='btn main-btn text-white rounded-pill fw-medium px-4 shadow-sm' onClick={()=>{
                navigate('/add_tutor')
            }}>BECOME A TUTOR</button>
        </div>
        </div>
        <div className="col-lg-6 pt-4">
            <div className='main-form p-4 py-5'>
                <p className='fs-2 fw-bold text-center' style={{color:'#FFCB24'}}>Join to the best Classes</p>
                <p className='text-white text-center'>Find engaging lessons with flexible schedules and friendly tutors. Achieve your learning goals, one lesson at a time!</p>

                <div className='mt-4'>
                    <input type="text" className='form-control py-3 bg-white shadow-none border-0 mb-4' placeholder='Name' name="" id="" />
                    <input type="email" className='form-control py-3 bg-white shadow-none border-0 mb-4' placeholder='Email' name="" id="" />
                    <input type="tel" maxLength={10} className='form-control py-3 bg-white shadow-none border-0 mb-4' placeholder='Contact Number' name="" id="" />
                    <textarea type="text" className='form-control py-3 bg-white shadow-none border-0 mb-4' placeholder='Type your message here...' name="" id="" />
        <input
          type="text"
          placeholder="Enter CAPTCHA"
          value={inputValue}
          className='form-control py-3 bg-white shadow-none border-0 mb-3'
          onChange={(e) => setInputValue(e.target.value)}
        />
         <div className='d-flex justify-content-center align-items-center mb-3'>
          <span className='fw-bold fs-4 text-white' style={{letterSpacing:'3px'}}>{captcha}</span> {/* Display generated CAPTCHA */}
          <button type="button" className='btn border-0 ms-1' onClick={generateCaptcha}>
            <img src={require('../images/reCaptcha-removebg-preview.png')} width={30} alt="" />
          </button>
        </div>
      
      {isValid ? (
        <p style={{ color: 'green' }}>CAPTCHA is valid!</p>
      ) : (
        inputValue && <p style={{ color: 'red' }}>CAPTCHA is invalid!</p>
      )}

                    <button className='btn text-dark fw-bold w-100 py-3 d-flex align-items-center justify-content-center' style={{backgroundColor:'#FCC106'}}>Get Started 
                        <span className='ms-1'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></span>
                    </button>

                </div>
            </div>
        </div>
       </div>
      </div>

      {/* Layout 2 */}
      <div className="main-div2 bg-white py-4">
      <div>
    <p className='fs-4 fw-medium text-center m-0'>Find Your</p>
    <p className='fs-1 fw-bold text-center m-0'>Ideal Tutor in Just a Few Clicks!</p>
    <p className='text-center mb-5'>Personalized tutoring sessions tailored to your needs. Book now and start excelling!</p>
</div>
      <div className="row m-0 pt-4 container mx-auto align-items-center">
<div className="col-lg-6 px-md-5">
    <img src={require('../images/page1/Frame.png')} style={{width:'80%'}} alt="" />
</div>
<div className="col-lg-6 px-md-5 mt-4 mt-lg-0">
    <p className='m-0 fs-5 fw-medium'>Why Choose</p>
    <p className='fs-1 fw-bold mb-4'>Merida Tuition Tutor?</p>

    <div className='d-flex align-items-start'>
    <img src={require('../images/icon 2/education.png')} width={48} className='me-4' alt="" />

        <div>
            <p className='fs-5 fw-bold m-0'>Personalized Learning Experience</p>
            <span style={{fontSize:'14px'}}>Each lesson is tailored to fit every student's individual needs and learning style, providing personalized, one-on-one attention to help you excel and overcome any challenges.</span>
        </div>
    </div>

    <div className='d-flex align-items-start mt-4'>
    <img src={require('../images/schedule.png')} width={46} className='me-4' alt="" />

        <div>
            <p className='fs-5 fw-bold m-0'>Flexible Scheduling
            </p>
            <span style={{fontSize:'14px'}}>We offer scheduling options that fit your schedule. Classes can be arranged in the morning, afternoon, or evening, providing flexibility to suit your availability.</span>
        </div>
    </div>

    <div className='d-flex align-items-start mt-4'>
        <img src={require('../images/tutor.png')} width={46} className='me-4' alt="" />
        <div>
            <p className='fs-5 fw-bold m-0'>Expert Tutors
            </p>
            <span style={{fontSize:'14px'}}>Our tutors are highly qualified and experienced specialists in their subject areas, providing high-quality instruction and effective teaching methods.</span>
        </div>
    </div>

    <div className='d-flex align-items-start mt-4'>
    <img src={require('../images/customer-service.png')} width={46} className='me-4' alt="" />

        <div>
            <p className='fs-5 fw-bold m-0'>Ongoing Support and Progress Tracking
            </p>
            <span style={{fontSize:'14px'}}>We provide support beyond academics, covering study skills and exam techniques. Our regular feedback and detailed progress reports keep students and parents well-informed and engaged.</span>
        </div>
    </div>
</div>
</div>
      </div>

{/* About Us */}
      <div className="m-0 row mt-5 align-items-center container mx-auto py-5">
      <div className="col-md-6 px-md-5 mt-2">
            <p className='fs-1 fw-bold'>About US</p>

           <p className=''>At Merida Tuition Tutor, we believe in making learning a joyous and rewarding experience. Our team of top-rated tutors is dedicated to helping students excel in all areas of study. From school subjects like math, physics to advanced programming and languages, we are here to support your academic and personal growth.</p>

           <button className='btn rounded-pill btn-primary px-4' onClick={()=>{
            navigate('/how-it-works')
           }} style={{fontSize:'13px'}}>Read more</button>
        </div>

        <div className="col-md-6 px-md-5 mt-2 text-end">
            <img src={require('../images/page1/illustrator.png')} style={{width:'80%'}} alt="" />
        </div>

        <Benefits_to_student/>
      </div>    

{/* What will you be */}
<What_will_you_be/>

{/* Rewards */}
<Assessment/>

<div className='container mb-5'>
<p className='fw-bold fs-1 mb-3 mt-5'>How to Join</p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Easily find and book sessions from a variety of classes with flexible schedules. Connect with top tutors and start learning right away.</p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" />Passionate about teaching? Share your knowledge and inspire students. Apply now to start making a difference as a tutor!</p>
</div>

{/* Subjects,Languages,Boards */}
<div className='review py-5'>
<div className="container">
    <p className='fs-1 fw-bold text-white text-center'>Categories</p>

    <div className='row m-0 mt-4'>
        <div className="col-md-6 mx-auto rounded-pill" style={{backgroundColor:'rgba(0,0,0,0.1)'}}>
            <div className="row m-0 w-100 py-2">
                <div className="col-4 ps-md-0">
                    <p onClick={()=>{
                        setstate('language')
                    }} className={`my-auto py-2 rounded-pill text-center ${state==='language' ? 'selected-btn':'select-btn'}`} style={{cursor:'pointer'}}>Languages</p>
                </div>
                <div className="col-4">
                    <p onClick={()=>{
                        setstate('subject')
                    }} className={`my-auto py-2 rounded-pill text-center ${state==='subject' ? 'selected-btn':'select-btn'}`} style={{cursor:'pointer'}}>Subjects</p>
                </div>
                <div className="col-4 pe-md-0">
                    <p onClick={()=>{
                        setstate('board')
                    }} className={`my-auto py-2 rounded-pill text-center ${state==='board' ? 'selected-btn':'select-btn'}`} style={{cursor:'pointer'}}>Boards</p>
                </div>
            </div>
        </div>
    </div>

{/* Languages */}
    <div className={`row m-0 mt-3 ${state==='language' ? '':'d-none'}`}>
    <p className={`text-white text-center my-4 mb-5`}>Nullam vestibulum, justo vitae varius interdum, nibh turpis eleifend tellus, sit amet porttitor orci erat vitae odio. Duis sapien metus, egestas rhoncus metus id</p>

        {languages.slice(0,6).map((x,index)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-4" style={{cursor:'pointer'}} onClick={()=>navigate(`/language/${x.slug}`)}>
            <div className='lang-div shadow'>
                <div className="text-center">
                    <Main_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>{x.name}</p>
                <p className='mt-4 text-center'>{x.mainText}</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
            )
        })}

        <div className='text-center mt-3'>
        <button className='btn btn-light px-4 py-2 fw-medium' onClick={()=>navigate('/languages')} style={{fontSize:'14px'}}>View All</button>
        </div>
        
    </div>

{/* Subjects */}
<div className={`row m-0 mt-3 ${state==='subject' ? '':'d-none'}`}>
    <p className={`text-white text-center my-4 mb-5`}>Nullam vestibulum, justo vitae varius interdum, nibh turpis eleifend tellus, sit amet porttitor orci erat vitae odio. Duis sapien metus, egestas rhoncus metus id</p>

        {subjects.slice(0,6).map((x,index)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-4" style={{cursor:'pointer'}} onClick={()=>navigate(`/subject/${x.slug}`)}>
            <div className='lang-div shadow'>
                <div className="text-center">
                    <Main_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>{x.name}</p>
                <p className='mt-4 text-center'>{x.content && x.content.slice(0,100)}...</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
            )
        })}

        <div className='text-center mt-3'>
        <button className='btn btn-light px-4 py-2 fw-medium' onClick={()=>navigate('/subjects')} style={{fontSize:'14px'}}>View All</button>
        </div>
        
    </div>

{/* Boards */}
<div className={`row m-0 mt-3 ${state==='board' ? '':'d-none'}`}>
    <p className={`text-white text-center my-4 mb-5`}>Nullam vestibulum, justo vitae varius interdum, nibh turpis eleifend tellus, sit amet porttitor orci erat vitae odio. Duis sapien metus, egestas rhoncus metus id</p>

        {boards.slice(0,6).map((x,index)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-4" style={{cursor:'pointer'}} onClick={()=>navigate(`/board/${x.slug}`)}>
            <div className='lang-div shadow'>
                <div className="text-center">
                    <Main_svg2/>
                </div>

                <p className='text-center fw-bolder fs-5 mt-4'>{x.name}</p>
                <p className='mt-4 text-center'>{x.paragraph1 && x.paragraph1.slice(0,100)}...</p>

                <img src={require('../../src/images/page1/divider.png')} className='d-block mx-auto mt-5' alt="" />
            </div>
        </div>
            )
        })}

        <div className='text-center mt-3'>
        <button className='btn btn-light px-4 py-2 fw-medium' onClick={()=>navigate('/boards')} style={{fontSize:'14px'}}>View All</button>
        </div>
        
    </div>
</div>



{/* Reviews */}
<div className="py-5 mt-4">
    <p className='fs-1 fw-bold text-white text-center'>Student’s Testimonials</p>
    <div className="row m-0 review-subdiv py-5 container mx-auto justify-content-between" style={{borderRadius:'15px'}}>
        <h5 className='text-white mb-4 ps-5'>Hear from our beloved Students</h5>
        <div className="col-lg-6 px-2 px-md-5 mb-4">
            <div className="row m-0 reviews-div">
                <div className="col-4">
                    <img src={require('../images/page1/Group 1171276044.png')} alt="" className='d-block mx-auto'/>
                    <p className='text-center text-white fw-medium m-0'>Madhavan</p>
                    <p className='text-center text-white' style={{fontSize:'13px'}}>Bangalore,Karnataka.</p>

                    <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={require('../images/page1/Group 85.png')} alt="" />
                            <img src={require('../images/page1/Group 83.png')} alt="" />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <img src={require('../images/page1/Group 88.png')} alt="" />
                            <img src={require('../images/page1/Group 84.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-8 ps-5 pe-0 d-flex flex-column justify-content-between">
                   <div>
                   <p className='fs-5 review-subdiv2 justify-content-center rounded p-1 fw-bold d-flex align-items-center text-white'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 3 <span className='ms-2 fw-normal' style={{fontSize:'14px'}}>Years of Experience</span></p>
                   <p className='text-white' style={{fontSize:'14px'}}>“The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time... The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time..</p>
                   </div>
                    <p className='text-white m-0' style={{fontSize:'14px'}}>Kingston School of Management and Science</p>

                </div>
            </div>
        </div>
        <div className="col-lg-6 px-2 px-md-5 mb-4">
            <div className="row m-0 reviews-div">
                <div className="col-4">
                    <img src={require('../images/page1/Group 1171276044.png')} alt="" className='d-block mx-auto'/>
                    <p className='text-center text-white fw-medium m-0'>Madhavan</p>
                    <p className='text-center text-white' style={{fontSize:'13px'}}>Bangalore,Karnataka.</p>

                    <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={require('../images/page1/Group 85.png')} alt="" />
                            <img src={require('../images/page1/Group 83.png')} alt="" />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <img src={require('../images/page1/Group 88.png')} alt="" />
                            <img src={require('../images/page1/Group 84.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-8 ps-5 pe-0 d-flex flex-column justify-content-between">
                   <div>
                   <p className='fs-5 review-subdiv2 justify-content-center rounded p-1 fw-bold d-flex align-items-center text-white'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 5 <span className='ms-2 fw-normal' style={{fontSize:'14px'}}>Years of Experience</span></p>
                   <p className='text-white' style={{fontSize:'14px'}}>“The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time... The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time..</p>
                   </div>
                    <p className='text-white m-0' style={{fontSize:'14px'}}>Kingston School of Management and Science</p>

                </div>
            </div>
        </div>
        <div className="col-lg-6 px-2 px-md-5 mb-4 mb-lg        -0">
            <div className="row m-0 reviews-div">
                <div className="col-4">
                    <img src={require('../images/page1/Group 1171276044.png')} alt="" className='d-block mx-auto'/>
                    <p className='text-center text-white fw-medium m-0'>Madhavan</p>
                    <p className='text-center text-white' style={{fontSize:'13px'}}>Bangalore,Karnataka.</p>

                    <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={require('../images/page1/Group 85.png')} alt="" />
                            <img src={require('../images/page1/Group 83.png')} alt="" />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <img src={require('../images/page1/Group 88.png')} alt="" />
                            <img src={require('../images/page1/Group 84.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-8 ps-5 pe-0 d-flex flex-column justify-content-between">
                   <div>
                   <p className='fs-5 review-subdiv2 justify-content-center rounded p-1 fw-bold d-flex align-items-center text-white'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 2 <span className='ms-2 fw-normal' style={{fontSize:'14px'}}>Years of Experience</span></p>
                   <p className='text-white' style={{fontSize:'14px'}}>“The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time... The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time..</p>
                   </div>
                    <p className='text-white m-0' style={{fontSize:'14px'}}>Kingston School of Management and Science</p>

                </div>
            </div>
        </div>
        <div className="col-lg-6 px-2 px-md-5">
            <div className="row m-0 reviews-div">
                <div className="col-4">
                    <img src={require('../images/page1/Group 1171276044.png')} alt="" className='d-block mx-auto'/>
                    <p className='text-center text-white fw-medium m-0'>Madhavan</p>
                    <p className='text-center text-white' style={{fontSize:'13px'}}>Bangalore,Karnataka.</p>

                    <div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={require('../images/page1/Group 85.png')} alt="" />
                            <img src={require('../images/page1/Group 83.png')} alt="" />
                        </div>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <img src={require('../images/page1/Group 88.png')} alt="" />
                            <img src={require('../images/page1/Group 84.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-8 ps-5 pe-0 d-flex flex-column justify-content-between">
                   <div>
                   <p className='fs-5 review-subdiv2 justify-content-center rounded p-1 fw-bold d-flex align-items-center text-white'><img src={require('../images/page1/star.png')} className='me-2' alt="" /> 4 <span className='ms-2 fw-normal' style={{fontSize:'14px'}}>Years of Experience</span></p>
                   <p className='text-white' style={{fontSize:'14px'}}>“The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time... The annual is named as 'SANGRILLA' which is the best thing I want to talk about and which is the best time..</p>
                   </div>
                    <p className='text-white m-0' style={{fontSize:'14px'}}>Kingston School of Management and Science</p>

                </div>
            </div>
        </div>
    </div>
</div>
</div>

{/* Rules */}
<div className='row m-0 container mx-auto py-5 align-items-center'>
<p className='fs-1 fw-bold text-center mb-5'>Rules and Guidelines</p>

<div className="col-md-6">          
    <img src={require('../images/page1/Group 114.png')} style={{width:'90%'}} alt="" />
</div>
<div className="col-md-6 px-2 px-md-5">
    <p className='fw-bold mb-4 fs-5'>Key Point</p>
    <p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Add maximum details to your answer.</p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> There should be no plagiarism and negative content about the college. </p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Review should be written only for the courses offered by the college.</p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> Mention facts and figures as per your best knowledge. </p>
<p className='d-flex align-items-center'><img src={require('../images/page1/Group 107.png')} className='me-2' alt="" /> A good validated review will also lead to better reward. </p>

<button className='px-5 fw-medium btn py-2 mt-4' style={{color:'#8236C8',border:'1px solid #8236C8'}}>Read Terms & Guidelines</button>
</div>
</div>

<div className='row m-0 container mx-auto py-5 align-items-center justify-content-center'>

<div className="col-md-5 px-md-5">
    <p className='fs-5 fw-medium m-0'>Help millions of students in making informed career decisions.</p>
</div>
<div className="col-md-5 px-2 px-md-5">
<button className='px-5 fw-medium btn py-2 text-white' style={{backgroundColor:'#8236C8',border:'1px solid #8236C8'}}>Join and Refer to your friend</button>
</div>
</div>

      <div>

      </div>
      <Main_footer/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
  )
}

export default Main_page
